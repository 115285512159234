<template>
  <TTableAdvance
    :items="roles"
    :fields="fields"
    @click-clear-filter="clearFilter"
    store="auth.role_list"
    resource="/admin/roles"
    enterable
    class="mb-4"
  >
    <template #id="{ item }">
      <td>
        <TMessage :content="item.id" noTranslate />
      </td>
    </template>
    <template #name="{ item }">
      <td>
        <TMessage :content="item.name" noTranslate />
      </td>
    </template>
    <template #permissions_count="{ item }">
      <td class="text-center">
        <TMessage
          :content="item.permissions_count"
          noTranslate
          alignment="center"
        />
      </td>
    </template>
    <template #users_count="{ item }">
      <td>
        <TMessage :content="item.users_count" noTranslate alignment="center" />
      </td>
    </template>
    <template #id-filter>
      <TInputText :value.sync="filter.id" @update:value="filterChange" />
    </template>
    <template #name-filter>
      <TInputText :value.sync="filter.name" @update:value="filterChange" />
    </template>
  </TTableAdvance>
</template>

<script>
import { mapGetters } from "vuex";
export default {
  data() {
    return {
      fields: [
        { key: "_", _style: "width: 20px" },
        { key: "id", label: "Role id", _style: "width:200px" },
        { key: "name", label: "Role" },
        {
          key: "permissions_count",
          label: "Permission",
          _classes: "text-center",
        },
        { key: "users_count", label: "Users", _classes: "text-center" },
      ],
      filter: {},
    };
  },
  created() {
    this.$store.dispatch("auth.role_list.fetch.if-first-time");
  },
  computed: {
    ...mapGetters({
      roles: "auth.role_list.list",
    }),
    id() {
      return this.$route.params.id;
    },
  },
  methods: {
    filterChange() {
      let defaultFilter = {
        include: "permissionsCount,usersCount",
      };
      const filterList = this.lodash.mapKeys(
        this.filter,
        function (value, key) {
          return `filter[${key}]`;
        }
      );
      const filterFields = this.lodash.pickBy({
        ...filterList,
        ...defaultFilter,
      });
      this.$store.dispatch("auth.role_list.apply-query", filterFields);
    },
    clearFilter() {
      this.filter = {};
      this.filterChange();
    },
  },
};
</script>
